function increaseNumber(elementId, number) {
  const step = Math.max(Math.floor(number / 150), 1);
  console.log({step})
  const element = $(`#${elementId}`)
  let interval = setInterval(() => {
    let txt = element.text();
    txt = txt.replace(/,/g, '')
    const currentNumber = Number(txt) || 0;
    if (currentNumber >= number) {
      clearInterval(interval);
      return;
    }
    const nextNumber = Math.min(number, currentNumber + step);
    element.text(nextNumber.toLocaleString());
  }, 15);
}
$('body').ready(function () {
  $("img[data-type='chart-state']").hide()
  let i = 1
  setInterval(() => {
    const newIndex = i + 1
    jQuery(`img[data-chart-state=${i%2}]`).fadeOut(1000)
    jQuery(`img[data-chart-state=${newIndex%2}]`).fadeIn(2000)
    i = newIndex
  }, 4000)
  $.get('https://api.soltable.xyz/stats', (data) => {
    increaseNumber('stat_1', data?.accounts);
    increaseNumber('stat_2', data?.tables);
    increaseNumber('stat_3', Math.floor((data?.duplicates || 0) * 100));
  })
})
